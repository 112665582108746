import React from "react"
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";

import Layout from "../components/layout"
import Rule from '../components/rule';

const BioPage = (props) => {
    const { data: { allFile: { nodes } } } = props;

    let index = 0;

    const gallery = nodes.map(node => (
        <Link to={`/meedia/${node.name}#main-body`}>
            <Img
                key={index++}
                itemProp="image"
                fixed={node.childImageSharp.fixed}
                alt=""
            />
        </Link>
    ));

    return (
      <Layout>
        <Helmet>
            <title>Artur Sirgu lugu | SA Mälestusselts „Artur Sirk“</title>
            <meta property="og:title" content="SA Mälestusselts „Artur Sirk“ | Artur Sirgu lugu" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.artursirk.ee/artur-sirk/" />
            <meta property="og:description" content="1930. aastate keskpaigas leidis Eesti Vabariik end ajaloo keeristes. Iseseisvuse võitmisest Eesti Vabadussõjas oli möödunud rohkem, kui dekaad. Poliitiline maastik oli ärev, ning õhus oli tunda saabuvate muutuste hõngu, sest ükski valitsus üle aasta ei kestnud. Oma poliitilise kõrgpunkti saavutanud Vabadussõjalaste liikumine erakondade süsteemi otstarbekaks ei pidanud, sest rahvast kasutati ainult hääletusmaterjalina. 1930. aastate alguses esitati kolm põhiseadust rahvahääletusele ja tugevat rahva poolehoidu nautinud Artur Sirgu ja Leopold Tõnsoni esitatud põhiseadus hakkas kehtima 1934. aastal. Rõõm jäi toona küll üürikeseks…" />
        </Helmet>
        <Rule />
        <blockquote>
			„Ei ole vähematki põhjust kartuseks, asja otsustab rahva poolt antud häälte arv.
			Kui saame vähem hääli, siis kaotame. Järgmisel korral valime jälle. Et midagi
			vägivaldset või seadusevastast ette võetakse, seda ma ei usu ju ometi, sest vastased on ju
			aumehed ja demokraadid. Ega nad ei või ju ometi põhiseadust rikkuda ja vägivalda tarvitada!“ —Artur Sirk
        </blockquote>
        <Rule />
        <h2>Artur Sirk</h2>
        <h4 className="title">Vabadussõjalaste liidu juht, advokaat ja reservohvitser</h4>
        <p>
            1930. aastate keskpaigas leidis Eesti Vabariik end ajaloo keeristes. Iseseisvuse võitmisest Eesti Vabadussõjas oli möödunud rohkem, kui dekaad. Poliitiline maastik oli ärev, ning õhus oli tunda saabuvate muutuste hõngu, sest ükski valitsus üle aasta ei kestnud. Oma poliitilise kõrgpunkti saavutanud Vabadussõjalaste liikumine erakondade süsteemi otstarbekaks ei pidanud, sest rahvast kasutati ainult hääletusmaterjalina. 1930. aastate alguses esitati kolm põhiseadust rahvahääletusele ja tugevat rahva poolehoidu nautinud Artur Sirgu ja Leopold Tõnsoni esitatud põhiseadus hakkas kehtima 1934. aastal. Rõõm jäi toona küll üürikeseks…
        </p>
        <h3>Artur Sirk</h3>
        <p>
            Artur Johann Sirk sündis 25. septembril 1900 taluomaniku pojana. Kasvas üles Järvamaal, Lehtse vallas Sepa talus. 1918. aastal Vabadussõjas liitus vabatahtliku õppursõdurina Tallinna kooliõpilaste rooduga ja Tapa vallutamisest Tartu vallutamiseni oli soomusrongi nr. 2 luurekommando ülema William Rakfeldti käsutuses, ning sai vahvuse eest VR II/3. Hiljem jätkas sõjateenistust, samal ajal õppides Tartu Ülikoolis õigusteadust. Kapten Bernhard Kolk on teda mäletus-kirjutises meenutanud niimoodi: „Artur Sirk ilmus teenistusse täpselt, piinlikult puhtas ja hästi korrashoitud riietuses, kõneles lühidalt ja asjalikult, vaatas igale otse silma, oli viisakas ja abivalmis ja jättis igale hästikasvatatud inimese mulje.“ Sirk jõudis täisikka Vabariigi algusaastail ja tundis kohusetunnet välja astuda riigi eest, mille nimel oli Vabadussõjas osalenud. Oli abiellunud Hilda Annoveriga, kellega abielust sündis tütar Viivu.
        </p>
        <p>
            Tema harukordne energia, loogiline ja selge mõtteviis, ning kõneoskus tegid temast loomuliku juhi Vabadussõjalaste liidule.
        </p>
        <h3>Vabadussõjalaste põhiseadus</h3>
        <p>
            1930. aastate alguses toimus põhiseaduse muutmise üle kolm rahvahääletust. Esimesed kaks kuigi edukad ei olnud. 19. juulil 1932 astus ametisse peamiselt majanduslike eesmärkidega Karl Einbundi valitsus. Esimene rahvahääletus toimus 1932. aasta augustis, ning suhteliselt võrdväärselt leidis 333 947 poolt- ja 345 215 vastuhäält. Valitsus vahetus 1. novembril 1932 ja jälle 18. mail 1933, kui ametisse astus Jaan Tõnissoni neljas valitsus. 1933. aastal korraldati teine rahvahääletus põhiseaduse muutmise üle, ning sellele oli 161 338 poolt- ja 331 000 vastuhäält. 14. – 16. oktoobril 1933 toimus uus hääletus, seekord Artur Sirgu ja Leopold Tõnsoni esitatud põhiseaduse üle. Eelnõu leidis 416 878 poolt- ja 156 849 vastuhäält, ning uus põhiseadus muutus kehtivaks 24. jaanuaril 1934.
        </p>
        <p>
            Kuigi põhiseadus leidis juristide kriitikat üksikasjade osas, oli põhiseaduse jõustumine kindel võit Vabadussõjalaste liidule. Uue põhiseaduse kohaselt oli Riigikogu liikmete arvuks 50 ja riigivanem tuli valida otse rahva poolt. Head eeldused riigivanemaks saada olid rahva seas populaarsel Andres Larkal, ning kui parlamendivalimised oleksid olnud vabadussõjalastele edukad, siis oleks peaministriks saanud tõenäoliselt Artur Sirk.
        </p>
        <h3>Päts-Laidoneri võimuhaaramine</h3>
        <p>
            Nagu ikka, saabusid tähtsad ajaloo murdepunktid ette hoiatamata. Ilmselgelt ei olnud valitsev klikk rahul sellega, et olemasolevad positsioonid läheksid vabadel valimistel kaduma. 1933. aasta Põhiseaduse järgi oli Sõjavägede Ülemjuhataja nimetamine võimalik ainult sõja- ja mobilisatsiooni puhul, kuid just siis, ilma igasuguse sõjaolukorrata kehtestati kaitseseisukord, ning Johan Laidoner nimetati kaitsevägede ülemjuhatajaks. Samal õhtul vangistati Sirk EVL peakorteris koos teiste vabadussõjalastega ilma ühegi süüdistusteta.
        </p>
        <p>
On teada, et mitu kuud pärast vangistamist viidi Sirk Narva maanteel asuvasse haiglasse. 11. novembri õhtupoolikul Sirk vanglast põgenes, ning siirdus läbi Tartu Vindavisse Lätis. Edasi jõuab 4. detsembril 1934 koos vangivaht Küttimiga Helsinkisse, kus ta sai Soome võimude poolt asüüli. Kuigi Sirku Soomelt välja ei nõutud, sest Soome oli riigipöörde osas hukkamõistev, on teada, et Sirgu vastu toimus aktiivne jälitustegevus. Samal ajal jätkus kohtuprotsess Eestisse jäänud Vabadussõjalaste vastu, ning neid süüdistati seadusparagrahvi alusel, mis kehtestati alles pool aastat pärast nende vahistamist; süüdistajaks Tshistjakov, eestistatud nimega Helk, kes oli K. Pätsi naisevend.
        </p>
        <p>
1935. aasta 8. detsember. Sellel õhtul asus Sirk teele Soomest Rootsi poole, ning jätkas läbi Inglismaa Hollandisse. Eesti esitas Hollandi valitsusele Sirgu vastu kolm vahistamise ja väljaandmise nõuet koos tema täpse aadressiga Hollandis. Sirk vahistati, viibis lühikest aega Rotterdami vanglas, kuid poliitilise iseloomu tõttu nõudeid ei rahuldatud. Pärast vabanemist tungiti temale Hollandis kaks korda järjest kallale.
        </p>
        <h3>Surm</h3>
        <p>
            1937. kevadeks oli Sirgul valminud käsikiri Eesti poliitilise olukorra kohta ja võimuhaaramise kohta.  18. mail 1937 saabus Sirk Luksemburgi ja juuni lõpus asus ta kõrvalisse Echternachi linna. Hotelli omaniku hr. Wengleri tütre sõnul käis 30. juulil Sirk linnas jalutamas ja märkas eestlasi. Järgmine päev käis ta jälle jalutamas ja oli ehmatanud ja märg. Samal õhtupoolikul leiti ta meelemärkuseta betoneeritud hoovilt, seinast 3 meetri kaugusel, kontide otsad jalgadest väljas. Teada on, et Sirk suri meelemärkusele tulemata 2. augusti varahommikul ja maeti Echternachti kalmistul. 1937. aastal toimus ümbermatmine Hietaniemi kalmuaeda, ning seda korraldasid Akadeemiline Karjala Selts, „Sinumusta“ toimetus, Soome Naisüliõpilaste Selts ja teised organisatsioonid. Need, kes Sirgu matustelt naasid, vahistati sadamas.
        </p>
        <Rule />
        <p className="hide-on-print" id="media">Klõpsa eelvaatel, et suurelt näha:</p>
        <div className="media">
            {gallery}
        </div>
        <Rule last />
      </Layout>
    );
};

export default BioPage;

export const query = graphql`
    query {
        allFile(filter: { extension: { eq: "jpg" } }) {
            nodes {
                childImageSharp {
                    fixed(width: 250, height: 250) {
                        ...GatsbyImageSharpFixed
                    }
                }
                name
            }
        }
    }
`;